

































































import { Component, Vue } from 'vue-property-decorator'
import {
    resetPassword,
} from '@/api/userapi/users'
import VsInputPassword from '@/components/VsInputPassword/Index.vue'
@Component({
    name: 'CheckResetPage',
    components: {
        VsInputPassword,
    },
})
export default class extends Vue {
    private passwordResetted = false
    private passwordResettedError = false
    private password = ''
    $refs: any
    private isValid (e: any) {
        this.passwordResetted = e
    }

    private isPasswordResettedError (e: any) {
        this.passwordResettedError = e
    }

    async createNewPassword () {
        try {
            await resetPassword(this.password, this.$route.params.resetId)
            this.passwordResetted = true
        } catch (e) {
            console.log(e)
            this.passwordResettedError = true
        }
    }

    mounted () {
        const loader = document.getElementById('first-loader')
        if (loader) loader.style.display = 'none'
    }
}
