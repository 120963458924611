























































import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
    name: 'VsInputPassword',
})

export default class extends Vue {
    @Prop({ required: true, default: false }) failedRules!: any
    @Prop({ required: true, default: '' }) value!: string
    @Prop({ required: false, default: '' }) label?: string
    @Prop({ required: false, default: 'medium' }) size?: string
    @Prop({ required: false, default: '' }) name?: string
    @Prop({ required: false, default: '' }) identifier?: string
    @Prop({ required: false, default: '' }) placeholder?: string
    private passwordType = 'password'

    private getPasswordValidationClasses (name: string) {
        return {
            'vs-text-grey-900': Object.keys(this.failedRules).length === 0 && !this.value,
            'vs-text-success-900': !(name in this.failedRules) && this.value,
        }
    }

    get dynamicClasses () {
        return {
            'vs-text-alert-500': Object.keys(this.failedRules).length > 0,
        }
    }

    private emitValue (e: any) {
        this.$emit('input', e)
    }

    private setPasswordType () {
        if (this.passwordType === 'password') {
            this.passwordType = 'text'
        } else {
            this.passwordType = 'password'
        }
    }
}
